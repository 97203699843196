@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rufina:wght@400;700&display=swap');



/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Rufina', serif; */


:root {
	--primary-black: #16263E;
	--grey-color: #828282;
	--white-color: #fff;
	--black-color: #000;
	--bg-color: #f2f8f6;
	--trans: all 0.3s ease-in-out;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 5px;
}

/* Style the scrollbar handle */
::-webkit-scrollbar-thumb {
	background: #09487C;
	border-radius: 5px;
}

/* ===============FONT COLORS================== */
.text-primary {
	color: var(--primary-black);
}

.text-grey {
	color: var(--grey-color);
}

.text-white {
	color: var(--white-color);
}

.text-black {
	color: var(--black-color);
}

/* ===============BACKGROUND COLOR================== */
.bg-primary-color {
	background-color: var(--bg-color);
}

.primary-heading {
	font-family: 'Rufina';
	font-weight: 700;
	font-size: 40px;
	letter-spacing: 0.02em;
	color: #16263E;
}


* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

a,
a:hover,
a:focus {
	color: #333;
	text-decoration: none;
	transition: all 0.3s;
}


ul,
li {
	text-decoration: none;
	list-style: none;
	margin-left: 0;
}


body {
	font-family: 'Poppins', sans-serif;
}

html {
	scroll-behavior: smooth;
}



/********************** HEADER CSS STARTED *********************/

.header {
	display: flex;
    align-items: center;
    margin: auto;
	vertical-align: middle;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 2;
	height: 96px;
	background-color: var(--bg-color);
	-webkit-transition: all 0.5s ease !important;
	-moz-transition: all 0.5s ease !important;
	-ms-transition: all 0.5s ease !important;
	-o-transition: all 0.5s ease !important;
	transition: all 0.5s ease !important;
	box-shadow: 0px 0px 30px rgb(127 137 161 / 30%);
}

.header.shrinkHeader {
	height: 74px;
	transition: all 0.5s ease !important;
}

header .headerContainer {
	background: var(--bg-color);
}

header .headerContainer .navbar-nav .nav-link {
	font-size: 16px;
	color: #000000;
	font-weight: 400;
	padding: 0 20px !important;
}

header .headerContainer .navbar-nav:last-child .nav-link {
	padding-right: 0;
}

header .headerContainer .navbar-nav .nav-link.active {
	font-weight: 600;
	color: var(--primary-black);
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0 !important;
	box-shadow: 0 0 0 0.1rem #15386a76 !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	font-weight: 600;
	color: var(--primary-black);
}


/*********************** HERO SECTION STARTED *************************** */

.mainWrapper {
	scroll-behavior: smooth;
}

.mainWrapper p {
	color: var(--primary-black);
}

.heroSection .heroSectionWrapper {
	background: url('../images/hero-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 96px;
}

.heroSection .heroSectionWrapper .heroSectionContent {
	padding: 100px 0 20px 0;
	align-items: center;
}

.heroSection .heroSectionWrapper .heroSectionContent .leftContent h1 {
	font-weight: 400;
	font-size: 44px;
	line-height: 60px;
	color: var(--primary-black);
	margin-bottom: 20px;
}

.heroSection .heroSectionWrapper .heroSectionContent .leftContent p {
	font-weight: 400;
	font-size: 18px;
	line-height: 36px;
	color: var(--primary-black);
}

.heroSection .heroSectionWrapper .heroSectionContent .rightContent {
	text-align: end;
}

.heroSection .heroSectionWrapper .heroSectionContent .rightContent img {
	max-width: 100%;
}

/*********************** HERO SECTION ENDED *************************** */


/*********************** ABOUT SECTION STARTED *************************** */
.aboutSection {
	background: var(--bg-color);
	padding-top: 100px;
}

.aboutSection .aboutSectionWrapper .aboutContent {
	padding: 26px 0;
}

.aboutSection .aboutSectionWrapper .aboutContent .aboutRight h2 {
	font-weight: 700;
	font-size: 40px;
	color: var(--primary-black);
	letter-spacing: 0.02em;
	margin-bottom: 10px;
	font-family: 'Rufina', serif;
}

.aboutSection .aboutSectionWrapper .aboutContent .aboutRight p {
	font-weight: 400;
	font-size: 18px;
	line-height: 36px;
	letter-spacing: 0.02em;
	text-align: justify;
}

/*********************** ABOUT SECTION ENDED *************************** */


/*********************** PRODUCT SECTION STARTED *************************** */
.productSection {
	background: var(--bg-color);
}

.productSection .productSectionWrapper {
	padding: 100px 0 0 0;
}

.productSection .productCard {
	margin-bottom: 30px;
}

.productSection .productCardWrapper {
	background-color: transparent;
	width: 100%;
	height: 280px;
	perspective: 1000px;
}

.productSection .productCardContent {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.productSection .productCardWrapper:hover .productCardContent {
	transform: rotateY(-180deg);
}

.productSection .productCardFront,
.productSection .productCardBack {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.productSection .productCardFront {
	background-color: #fff;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.productSection .productCardFront p {
	font-weight: 600;
	font-size: 20px;
	margin-top: 20px;
	color: #16263E;
}

.productSection .productCardBack {
	background: linear-gradient(92.68deg, #16263E 0%, #223B60 100%);
	border-radius: 20px;
	color: white;
	transform: rotateY(-180deg);
	display: flex;
	align-items: center;
	padding: 20px;
}

.productSection .productCardBack ul {
	margin-bottom: 0;
	padding-right: 0;
}

.productSection .productCardBack ul li {
	list-style-type: disc;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 5px;
}

/*********************** PRODUCT SECTION ENDED *************************** */




/*********************** PARTNER SECTION STARTED *************************** */

.carousel-container {
	padding-bottom: 60px;
}

.react-multi-carousel-dot button {
	display: inline-block;
    width: 30px !important;
    height: 4px !important;
    border-radius: 0px !important;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: all 0.5s !important;
    border-width: 0px !important;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;
    background: #a8abaa !important;
}

.react-multi-carousel-dot--active button {
	background: var(--primary-black) !important;
}

.partnerSection {
	background: var(--bg-color);
	padding-top: 100px;
}

.partnerSection .partnerContent {
	padding-top: 40px;
	padding-bottom: 30px;
	background: #fff;
	border-radius: 20px;
	margin: 0;
}

.partnerSection .partnerImg {
	width: 100%;
	text-align: center;
}

.partnerSection .partnerImg img {
	padding-top: 5px;
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	transition: filter 600ms ease;
	-webkit-transition: -webkit-filter 600ms ease;
	transition: all 0.4s linear;
}

.partnerSection .partnerImg img:hover {
	filter: grayscale(0);
	-webkit-filter: grayscale(0);
	transform: scale(1.1, 1.1);
}

/*********************** PARTNER SECTION ENDED *************************** */



/*********************** ACHIEVEMENT SECTION STARTED *************************** */
.achievementSection {
	background: var(--bg-color);
}

.achievementSection .achievementContent {
	padding: 85px 0 0 0;
}

.achievementSection .achievementDetail {
	text-align: center;
	border-right: 2px solid #828282;
}

.achievementSection .achievementDetail h3 {
	font-weight: 600;
	font-size: 60px;
	letter-spacing: 0.02em;
	color: #16263E;
}

.achievementSection .achievementDetail p {
	font-weight: 400;
	font-size: 20px;
	color: #828282;
}

/*********************** ACHIEVEMENT SECTION ENDED *************************** */

/*********************** CONTACT SECTION STARTED *************************** */
.contactSection {
	background: var(--bg-color);
	padding-top: 65px;
}

.contactSection .contactContent {
	padding: 35px 0;
}

.contactSection .contactLeft {
	text-align: right;
	height: 100%;
}

.contactSection .contactRight {
	padding: 50px 58px;
	border-radius: 5px;
	background-color: #fff;
}

.contactSection .contactRight .form label {
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.03em;
	color: var(--black-color);
	margin-bottom: 0px;
}

.contactSection .contactRight .form input.form-control,
.contactRight .form textarea.form-control {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: var(--black-color);
	border: 0;
	outline: none;
	border-bottom: 1px solid #000000;
	background: transparent;
	border-radius: 0;
	padding: 8px 0;
	/* margin-bottom: 30px; */
}

.contactSection .contactRight .form input.form-control:focus,
.contactRight .form textarea.form-control:focus {
	outline: none;
	box-shadow: none;
	border-bottom: 1px solid #15396A;
}

.contactSection .contactRight .form .submitBtn {
	background: linear-gradient(92.68deg, #16263E 0%, #223B60 100%);
	border-radius: 5px;
	padding: 12px 40px;
	font-weight: 500;
	font-size: 16px;
	color: var(--white-color);
}

/*********************** CONTACT SECTION ENDED *************************** */



/*********************** FOOTER SECTION STARTED *************************** */
.footerSection {
	background: linear-gradient(180deg, #16263E 0%, #223B60 100%);
}

.footerSection .footerContent {
	padding: 86px 0 45px 0;
}

.footerSection .footerLeft ul li {
	margin-bottom: 12px;
}

.footerSection .footerLeft ul li a {
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #fff;
}

.footerSection .footerMiddle ul li {
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #fff;
	margin-bottom: 12px;
}

.footerSection .copyContent {
	padding: 30px 0 68px 0;
	text-align: center;
}

.footerSection .copyContent p {
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #fff;
	margin-bottom: 0;
}

.footerRight iframe {
	width: 521px;
	height: 239px;
}

/*********************** FOOTER SECTION ENDED *************************** */





/*********************** RESPONSIVE CSS STARTED ********************/
@media only screen and (max-width: 1368px) {
	.heroSection .heroSectionWrapper .heroSectionContent {
		padding: 50px 0 20px 0;
	}
}

@media only screen and (max-width: 1199px) {

	.heroSection .heroSectionWrapper .heroSectionContent {
		padding: 20px 0;
	}

	.aboutSection .aboutSectionWrapper .aboutContent .aboutRight p {
		font-size: 16px;
		line-height: 30px;
	}

	.productSection .productCardFront p {
		font-size: 18px;
	}

	.productSection .productCardBack {
		padding: 10px;
	}

	.productSection .productCardBack ul li {
		font-weight: 400;
		font-size: 14px;
	}

	.partnerSection .partnerImg {
		padding: 0 20px;
	}

	.achievementSection .achievementDetail h3 {
		font-size: 45px;
	}

	.footerRight iframe {
		width: 400px;
		height: 200px;
	}
}

@media only screen and (max-width: 991px) {

	.header {
		height: 74px;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .rightContent img {
		max-width: 100%;
	}

	/* #basic-navbar-nav {
		display: none;
	} */

	header .headerContainer.shrinkHeader {
		height: 100%;
	}

	header .headerContainer .navbar-nav .nav-link {
		margin: 10px 0 !important;
		text-align: center;
	}

	header .headerContainer .navbar-nav {
		margin-top: 10px;
	}

	header .headerContainer .navbar-nav .nav-link {
		padding: 0;
	}

	.heroSection .heroSectionWrapper .heroSectionContent {
		padding: 70px 0;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .leftContent h1 {
		font-size: 32px;
		line-height: 50px;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .leftContent p {
		font-size: 16px;
		line-height: 30px;
	}

	.productSection .productSectionWrapper {
		padding: 100px 0 0 0;
	}

	.productSection .productCardBack ul li {
		font-weight: 400;
		font-size: 14px;
	}

	.achievementSection .achievementDetail h3 {
		font-size: 35px;
	}

	.achievementSection .achievementDetail p {
		font-size: 16px;
	}

	.footerRight iframe {
		width: 280px;
		height: 150px;
	}
}

@media only screen and (max-width: 767px) {

	.aboutSection {
		padding-top: 20px;
	}

	.partnerSection {
		padding-top: 100px;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .rightContent {
		text-align: center;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .rightContent img {
		width: 280px;
	}

	.aboutSection .aboutSectionWrapper .aboutContent .aboutLeft {
		text-align: center;
		margin-bottom: 30px;
	}

	.aboutSection .aboutSectionWrapper .aboutContent .aboutLeft img {
		width: 80%;
	}

	.partnerSection .partnerImg {
		padding: 0 5px;
	}

	.achievementSection .achievementDetail h3 {
		font-size: 30px;
	}

	.achievementSection .achievementDetail p {
		font-size: 14px;
	}

	.contactSection .contactLeft {
		text-align: center;
	}

	.contactSection .contactLeft img {
		width: 50%;
	}

	.contactSection .contactRight {
		margin-top: 40px;
		padding: 40px 40px;
	}

	.footerRight {
		margin-top: 20px;
		text-align: center !important;
	}

	.footerRight iframe {
		width: 100%;
		height: 200px;
	}
}

@media only screen and (max-width: 575px) {
	.headerContainer .navbar-brand img {
		width: 200px;
	}

	.heroSection .heroSectionWrapper .heroSectionContent {
		padding: 30px 0;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .leftContent h1 {
		font-size: 26px;
		line-height: 42px;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .leftContent p {
		font-size: 14px;
		line-height: 26px;
	}

	.heroSection .heroSectionWrapper .heroSectionContent .rightContent img {
		margin-top: 20px;
		width: 80%;
	}

	.aboutSection .aboutSectionWrapper .aboutContent .aboutLeft img {
		width: 100%;
	}

	.aboutSection .aboutSectionWrapper .aboutContent .aboutRight p {
		font-size: 14px;
		line-height: 26px;
	}

	.aboutSection .aboutSectionWrapper .aboutContent .aboutRight h2 {
		font-size: 32px;
		text-align: center;
	}

	.productSection .productSectionWrapper {
		padding: 100px 0 0 0;
	}

	.primary-heading {
		font-size: 32px;
	}

	.productSection .productCardFront p {
		font-size: 16px;
	}

	.productSection .productCardBack {
		padding: 5px;
	}

	.productSection .productCardBack ul li {
		font-weight: 300;
		font-size: 14px;
	}

	.partnerSection .partnerImg {
		padding: 0;
	}

	.achievementSection .achievementContent {
		padding: 40px 0;
	}

	.achievementSection .achievementDetail {
		border-right: 0;
		padding: 10px 0;
	}

	.contactSection .contactLeft img {
		width: 100%;
	}

	.contactSection .contactRight {
		padding: 15px;
	}

	.contactSection .contactRight .form input.form-control,
	.contactRight .form textarea.form-control {
		font-size: 14px;
	}

	.footerSection .footerContent {
		padding: 25px 0 20px 0;
	}

	.footerSection .footerLeft {
		margin-bottom: 20px;
	}

	.footerSection .footerLeft ul li a {
		font-size: 14px;
	}

	.footerRight iframe {
		height: 150px;
	}

	.footerSection .copyContent {
		padding: 30px 0 30px 0;
	}

}

/*********************** RESPONSIVE CSS ENDED ********************/